//ALL PLACES NOMINATE THE PAGES!!! EX:

/** HOMEPAGE */
section.intro{
  background-position: left;
  background-size: auto 100%;
  background-repeat: no-repeat;
  min-height: 47.375rem;
  padding-top: 3.75rem;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    width: calc(((100% - var(--container-width)) / 2)  + (var(--container-width) * 0.75) );
    height: 100%;
    background: $primary-color;
    border-bottom-right-radius: 7rem;
    z-index: -3;
  }
  & .img-wrap{
    padding: 6rem 0;
  }
}

section.about{
  padding: 8rem 0 3.5rem;
  & span.subtitle{
    display: block;
    padding-bottom: 2rem;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 2rem;
  }
  & ul{
    padding-left: 0.75rem;
    & li{
      position: relative;
      margin-bottom: 1.25rem;
     /* &::before{
        content: '';
        position: absolute;
        left: -0.5rem;
        top: 14px;
        width: 4px;
        height: 4px;
        background-color: $secondary-color;
        border-radius: 50%;
      }*/
    }
  }
}
section.dominios{
  padding: 16.25rem 0 6.25rem;
  position: relative;

  &::before{
    content: '';
    position: absolute;
    top: 3.5rem;
    right: 0;
    width: 70%;
    width: calc(((100% - var(--container-width)) / 2)  + (var(--container-width) * 0.75) );
    height: 90%;
    height: calc(100% - 7rem);
    background: #EDE7FD;
    border-top-left-radius: 20rem;
    z-index: -3;
  }
  & img.shape{
    position: absolute;
    right: 2rem;
    top: 15.25rem;
    z-index: -2;
  }
  & img.ball{
    position: absolute;
    right: 24rem;
    top: 9rem;
    z-index: -1;
  }
  & span.tit{
    font-weight: 600;
    font-size: 18px;
    line-height: 1.7;
    color: $secondary-color;
    display: block;
    margin-bottom: 0.5rem;
  }
  & .faqs{
    margin-top: 4.5rem;
    & .accordion-item{
      box-shadow: 0px 20px 40px rgba(112, 63, 240, 0.1);
      border: none;
      border-radius: 8px;

      margin-bottom: 1.25rem;
      & .accordion-body{
        padding: 1.25rem 2rem 1rem;
        & ul{
          padding-left: 1rem;
        }
        & li{
          //list-style: disc;
          margin-bottom: 1rem;
        }
      }
      & .accordion-button{
        padding: 1.25rem 4rem 1.25rem 2rem;
        border-radius: 8px;
        background-color: $white!important;
        box-shadow: none;

        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: $primary-color;
        display: block;

        span.subtitle {
          display: block;
        }

        &::after{
          //background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          //plus icon
          position: absolute;
          right: 25px;
          top: 32px;
          width: 16px;
          height: 2px;
          background: $secondary-color;
          transform: rotate(0deg);
        }
        &::before{
          content: '';
          position: absolute;
          right: 32px;
          top: 25px;
          width: 2px;
          height: 16px;
          background: $secondary-color;
          transition: transform .2s ease-in-out;
        }
        &:not(.collapsed)::before {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
section.mais-cuidado{
  padding: 10.25rem 0 9rem;
  border-bottom: 1px solid #E5E5E5;
  & .pack-wrapp{
    & span.value-b{
      font-size: 58px;
      font-weight: 600;
      line-height: 1;
    }
    & span.value-a{
      font-size: 18px;
      font-weight: 700;
    }
    & span.per-month{
      display: block;
      font-size: 16px;
      line-height: 1.75;
      color: $primary-color;
    }
    & span.per-person{
      display: block;
      font-size: 16px;
      line-height: 1.75;
      color: rgba($primary-color, 0.5);
      margin-bottom: 1.5rem;
    }
    & h5{
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 33px;
        height: 1px;
        background-color: #E5E5E5;
        transform: translateX(-50%);
      }

    }
    & p{
      padding: 0 2rem;
    }
  }
  & .note-wrapp{
    margin-top: 6.25rem;
    padding-top: 2rem;
    border-top: 1px solid #E5E5E5;
    & span{
      font-size: 18px;
      position: relative;
      &::before{
        content: "*";
        font-size: 18px;
        color: $secondary-color;
        font-weight: 700;
      }
    }
  }
  & span.subtitle{
    display: block;
    margin-bottom: 4.25rem;
  }
}
section.estamos{
  padding: 5rem 0px 9rem;
  border-top: 1px solid #E5E5E5;
  & span.subtitle{
    display: block;
    margin-bottom: 4.25rem;
  }
  & img{
    width: 100px;
    height: 100px;
  }
}
section.join{
  padding: 13.5rem 0 10.75rem;
  background-color: $primary-color;
  z-index: 0;

  &::before,
  &::after{
    content: '';
    position: absolute;
    top: 3.5rem;
    height: 100%;
    max-height: 890px;
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &::before{
    left: 0;
    background-image: url("../images/join-left.png");
    background-position: left;
  }
  &::after{
    right: 0;
    background-image: url("../images/join-right.png");
    background-position: right;
  }
  & h2,
  & span,
  & p{
    color: $white;
  }
  & .gform_wrapper {
    padding-top: 2.5rem;
    & label{
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: $white;

      margin-bottom: 0.5rem!important;
      padding-left: 1.5rem!important;
    }
    & .ginput_right{
      padding: 0!important;
      flex: 0 0 100%!important;
    }
    & input{
      border-radius: 2rem;
      background-color: $primary-color;
      border: 1px solid rgba(229, 229, 229, 0.2);
      padding: 1rem 1.5rem!important;
      margin-bottom: 0.25rem!important;

      font-size: 16px;
      line-height: 1.75;
      color: $white;
      opacity: 0.8;
      &.gform_button{
        background-color: $secondary-color;
        border-color: $secondary-color;
        transition-duration: 0.3s;
        transition-property: background-color, color, border-color;
        font-weight: 600;

        &:hover{
          background-color: $primary-color;
          color: $white;
        }
      }
    }
    & .gform_footer{
      text-align: center;
    }
    & .gfield_required{
      display: none;
    }
    & .gform_validation_errors,
    & .gfield_validation_message, 
    & .validation_message{
      background-color: $primary-color;
      border: none;
    }
    & .gform_validation_errors{
      padding: 1rem;
      box-shadow: none;
      & span{
        display: none;  
      }
    }
  }
}

/** TYP */
section.thanks{
  padding: 16rem 0;
  position: relative;
  &::before,
  &::after{
    content: '';
    position: absolute;
    top: 3.5rem;
    height: 100%;
    max-height: 890px;
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &::before{
    left: 0;
    background-image: url("../images/thanks-left.png");
    background-position: left;
  }
  &::after{
    right: 0;
    background-image: url("../images/thanks-right.png");
    background-position: right;
  }
  & h1{
    color: $secondary-color;
    margin-bottom: 1.5rem;
  }
}

/* Privacy */
.privacy-policy {
  section.thanks {
    padding: 7rem 0;
    .col-lg-12.text-center {
      text-align: left !important;
    }
    span.desc {
      li {
       // list-style: disc;
     }
     ul {
      padding-left: 1rem;
    }
  }
}
}

.accordion-body ul,
span.desc ul {
  list-style: none !important; /* Remove default bullets */
}

.accordion-body ul li::before,
span.desc ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #703FF0; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}




