/*FOOTER*/
footer {
  background-color: $primary-color;
  padding: 2.75rem 0 2.25rem;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::after{
    content: '';
    position: absolute;
    left: -1px;
    bottom: -1px;
    height: 100%;
    width: 100%;
    width: calc(((100% - var(--container-width)) / 2 ) + var(--container-width));
    border: 1px solid rgba(229, 229, 229, 0.2);
    border-top-right-radius: 5rem;
    background-color: $primary-color;
    z-index: -1;
  }

  & span{
    font-size: 14px;
    line-height: 1.7;
    color: rgba($white, 0.5);
  }
  & a{
    font-size: 14px;
    line-height: 1.7;
    color: $white;
    &:hover{
      color: $secondary-color;
    }
  }
  & .brand{
    display: block;
  }
  & .social{
    & a:nth-child(2){
      margin-left: 1rem;
    }
  }
}

@media only screen and (max-width: $breakpoint-lg) {
  footer {
    & .brand{
      margin-bottom: 1.5rem;
    }
    & span.rights{
      margin-bottom: 1rem;
      display: block;
    }
    & span.madeby{
      display: block;
      margin-top: 1rem;
    }
  }
}
@media only screen and (max-width: $breakpoint-md) {	
  footer{
    &:after {
      display: none;
    }
  }
}
/* @media only screen and (max-width: $breakpoint-xxl - 1) {
}

@media only screen and (max-width: $breakpoint-xl - 1) {
}

@media only screen and (max-width: $breakpoint-lg - 1) {
}

@media only screen and (max-width: $breakpoint-md - 1) {	
}

@media only screen and (max-width: $breakpoint-sm - 1) {
} */