@media only screen and (max-width: $breakpoint-lg) {
  section.intro{
    background-image: none!important;
    padding: 12rem 0;
    min-height: auto;
    &::before {
      width: 100%;
    }
    & .img-wrap{
      display: none;
    }
  }
  section.dominios{
    & img.ball {
      right: 0rem;
      top: 5rem;
    }
    & img.shape{
      right: -12rem;
      top: 13rem;
    }
  }
  section.mais-cuidado {
    padding: 5rem 0 5rem;
    & .pack-wrapp{
      margin-bottom: 3rem;
    }
    & .note-wrapp {
      margin-top: 3.25rem;
    }
  }
  section.estamos {
    padding: 5rem 0 5rem;
    & span.subtitle{
      display: block;
      margin-bottom: 2.5rem;
    }
    & img{
      margin: 1.5rem 0;
    }
  }
  section.join {
    padding: 5.5rem 0 6.5rem;
  }
}

@media only screen and (max-width: $breakpoint-md) {	
  section.about {
    padding: 6rem 0 3.5rem;
    & .img-wrap{
      margin-bottom: 2rem;
    }
  }
  section.mais-cuidado {
    padding: 3rem 0;
    & .note-wrapp {
      margin-top: 1.25rem;
    }
  }
  section.estamos {
    padding: 3rem 0;
  }
}
/* @media only screen and (max-width: $breakpoint-md - 1) {	
} */

/* @media only screen and (max-width: $breakpoint-sm - 1) {
} */