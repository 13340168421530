/* :::::: ALL CONTENT :::::: ; */

//BREAKPOINTS
$breakpoint-xs:0;
$breakpoint-sm:576px;
$breakpoint-md:768px;
$breakpoint-lg:1024px;
$breakpoint-xl:1280px;
$breakpoint-xxl:1440px;
$breakpoint-xxxl:1640px;

html {
	position: relative;
	scroll-behavior: smooth;

	&.over{
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}
}
body {
	overflow-x: hidden;
	
	&.over{
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}
}
address, dl, ol, ul {
	margin-bottom: 0;
}
li {
	list-style: none;
}
dl, ol, ul {
	margin-top: 0;
}
a{
	transition: all .3s ease-in;
	&:hover{
		color: inherit;
		text-decoration: none;
	}
}

/* :::::: WP STUFF :::::: ; */
.wp-caption {
	@extend .figure;
}
.wp-caption > img {
	@extend .figure-img;
	@extend .img-fluid;
}
.wp-caption-text {
	@extend .figure-caption;
}
.sidebar-primary .main {
	-ms-flex: 0 0 100% !important;
	flex: 0 0 100% !important;
	max-width: 100% !important;
}

/* :::::: UTILITIES :::::: ; */
.element-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	
	&.alignBaseline {
		align-items: baseline;
	}
}
.container-fluid {
	width: 100%;
	padding-right: 0px;
	padding-left: 0px;
	margin-right: auto;
	margin-left: auto;
}

/* :::::: FUNCTIONS :::::: ; */
@function HeightLines($tamanho, $lines:4){
	$height: $tamanho * $lines;
	@return #{$height}em;
}


/* :::::: MIXINS :::::: ; */
//colocar '...' no final de $lines linhas || Solucao vista e copiada dos sites da google! Funciona em praticamente todo lado (opera / mozilla out)
//@include dots(7, HeightLines(1.25, 7));
@mixin dots($lines:'', $height:'', $max-height:'', $min-height:'' ){
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	white-space: normal;
	word-break: break-word;

	@if $height != ""{
		height: $height;
		overflow: hidden;
	}
	@if $max-height != ""{
		max-height: $max-height;
		overflow: hidden;
	}
	@if $min-height != ""{
		min-height: $min-height;
		overflow: hidden;
	}
}
/* Over here */