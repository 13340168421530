/*404 THEME - name-of-theme*/

/** ERROR PAGE */
section.error{
  padding-top: 17.5rem;
  padding-bottom: 11.25rem; 
  background-color: $primary-color;
  & h1{
    font-style: normal;
    font-weight: bold;
    font-size: 136px;
    line-height: 1.41;
    color: $white;
    margin: 0;
  }
  & span.err{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.05em;
    color: $white;

    margin: 2rem 0;
    display: block;
  }
}

/* @media only screen and (max-width: $breakpoint-xxl - 1) {
}

@media only screen and (max-width: $breakpoint-xl - 1) {
}

@media only screen and (max-width: $breakpoint-lg - 1) {
}

@media only screen and (max-width: $breakpoint-md - 1) {	
}

@media only screen and (max-width: $breakpoint-sm - 1) {
} */