/*  FONTS, VARIABLES */

/* :::::: Fonts :::::: */
$primary-font: 'Poppins', sans-serif !default;
$secondary-font: 'Poppins', sans-serif;

/* :::::: Colors :::::: ; */
$primary-color: #0C2E36 !default; 
$secondary-color: #703FF0 !default;  
$error-color: #c00 !default; 

$white: #ffffff !default;  
$black: #000000 !default; 

$light-gray: #F2F2F2 !default; 
$medium-gray: #eeeeee !default; 
$dark-gray: #8993a4 !default; 

//colors up here, are obrigatory!
//more colores if make sence
$green: #29D345;

/* :::::: Styling Classes :::::: */
.black {
	color: $primary-color;
}
.white {
	color: $white;
}
.primary-color {
	color: $primary-color;
}
.secondary-color {
	color: $secondary-color;
}
.primary-bg {
	background-color: $primary-color;
}
.secondary-bg {
	background-color: $secondary-color;
}
.white-bg {
	background-color: $white;
}

/* ::::::  Font :::::: */
body {
	font-family: $primary-font;
}

/* :::::: Predefined Color :::::: */

/* :::::  Typo :::::: */
h1 {
	font-family: $secondary-font;
	font-size: 56px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: -0.03em;
	color: $light-gray;
	margin-bottom: 1rem;
}
h2{
	font-family: $secondary-font;
	font-size: 48px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: -0.03em;
	color: $primary-color;
	margin-bottom: 1rem;
}
h3{
	font-family: $secondary-font;
	font-size: 42px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	color: $primary-color;
	text-transform: uppercase;
}
h4 {
	font-family: $secondary-font;
	font-size: 38px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 120%;
	letter-spacing: normal;
	color: $primary-color;
}
h5 {
	font-family: $secondary-font; 
	font-size: 22px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.18;
	letter-spacing: normal;
	color: $primary-color;
}
span, 
p {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: $primary-color;
}

@media only screen and (max-width: $breakpoint-md) {	
	h2{
		font-size: 40px;
	}
}

/* ::::::  VARIABLES :::::: */
//use var(--container-width);
@media only screen and (min-width: 576px) {
	:root{
		--container-width: 540px;
	}
}

@media only screen and (min-width: 768px) {
	:root{
		--container-width: 720px;
	}
}

@media only screen and (min-width: 1024px) {
	:root{
		--container-width: 992px;
	}
}

@media only screen and (min-width: 1280px) {	
	:root{
		--container-width: 1148px;
	}
}

@media only screen and (min-width: 1440px) {
	:root{
		--container-width: 1320px;
	}
}