
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:focus {
		outline:none !important;
		box-shadow: none !important;
	}
}

select,
input:focus,
button:focus,
.btn:focus {
	outline: none !important;
}

a {
	text-decoration: none !important;
}

label {
  margin-bottom: 0 !important;
}


footer,
section {
	width: 100%;
	float: left;
	position: relative;
}

h1, h2, h3, h4, h5, h6, span, p {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.swiper-button-prev:hover, 
.swiper-button-prev:focus, 
.swiper-button-prev:active,
.swiper-button-next:hover, 
.swiper-button-next:focus, 
.swiper-button-next:active,
.swiper-prev:hover, 
.swiper-prev:focus, 
.swiper-prev:active,
.swiper-next:hover, 
.swiper-next:focus, 
.swiper-next:active {
	color: inherit !important;
	text-decoration: none !important;
	outline: none !important;
}

ul, li, ol, p {
	margin-top: 0;
	margin-bottom: 0;
	/*list-style-type: none;*/
}

ul {
	padding-left: 0px;
}

ul ul {
  padding-left: 20px;
}

html, body {
	-webkit-font-smoothing: antialiased;
}

.ieshow {
	display: none;
}

/* IE11 */
@media all and (-ms-high-contrast:none) {
	.ieshow {
		display: block !important;
	}
}

/* EDGE */
@supports (-ms-ime-align:auto) {
	.ieshow {
		display: block !important;
	}
}