header {
	background: transparent;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 114;
  padding: 1.5rem 0;

  & .nav-primary {
    margin-left: 1.5rem;
    & ul {
     & li {
      & a {
       font-style: normal;
       font-weight: 600;
       font-size: 16px;
       line-height: 1.5;
       color: $light-gray;
       margin: 0 1.75rem;
       transition: .3s;

       &:hover {
        color: $secondary-color;
      }
    }	
  }
}
}
&.scrolled {
  position: fixed;
  z-index: 100;
  background: #0C2E36;
  transition: .3s;
}
} 


/* MENU BURGER */
.burger-container {
  z-index: 100000;
  position: relative;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 10;
  & #burger {
    width: 30px;
    height: 25px;
    position: relative;
    display: block;
    margin: 2px auto 0;
    top: 50%;
    transform: translateY(-50%);
    & .bar {
      width: 100%;
      height: 2px;
      display: block;
      position: relative;
      background: $white;
      transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      transition-delay: 0s;
    }
    & .bar.topBar {
      transform: translateY(0px) rotate(0deg);

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 30px;
        height: 2px;
        background: $white;
      }
    }
    & .bar.btmBar {
      transform: translateY(18px) rotate(0deg);
    }
  }
}
header.menu-opened {
  //transition: all 0.3s;
  //background-color: $white;
  & .burger-container {
    transform: rotate(90deg);
    & #burger {
      & .bar {
        transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition-delay: 0.2s;
        //background: $primary-color;
      }
      & .bar.topBar {
        //background: $primary-color;
        transform: translateY(5px) rotate(45deg);

        &:after {
          //background: $primary-color;
          display: none;
        }
      }
      & .bar.btmBar {
        transform: translateY(3px) rotate(-45deg);
      }
    }
  }
}
#menu-full {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0px;
  z-index: 113;
  //display: none;
  background: $primary-color;

  //opacity: 0;
  transform: translateY(-120vh);
  //transition: transform 0.7s cubic-bezier(0.455, 0.03, 1, 0.545) 0s;
  transition: all .7s ease-in-out;
  //overflow: scroll;

  & .move-content{
    width: 100vw; 
    height: 100vh;
    display: flex;
    &.scroll{
      overflow-y: auto;
      z-index: 0;
    }
    & .minh-100{
      min-height: 100%!important;
    }
    & .content{
      padding-top: 138px;
      padding-bottom: 4.5rem;
    }
    & nav{
      height: 100%;
      display: flex;
      align-items: center;
      & ul{
        width: 100%;
        & li{ 
          width: 100%;
          display: block;
          padding: 0.5rem 0;

          opacity: 0;
          transform: translateX(-50px);
          & a{
            font-size: 18px;
            line-height: 1.5;
            letter-spacing: 0.2px;
            color: $white;
          }
        }
      }
    }
    & .button-wrap{
     transform: translateX(-50px);
     margin-top: 0.5rem;
     opacity: 0;
   }
 }

 &.opened{
  opacity: 1;
  transform: translateY(0px);
  & nav ul{
   $a: 3;
   @for $i from 1 through $a {
    & li:nth-child(#{$i}) {
     transition: all 0.6s ease #{$i * 0.1 + 0.5}s;
     opacity: 1;
     transform: translateY(0px);
   }
 }
}
& .button-wrap{
 transition: all 0.6s ease 0.9s;
 opacity: 1;
 transform: translateY(0px);
}
} 
}
#menu-bg{
  //opacity: 0;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 112;
  transform: translateY(-120vh);
  &.bg-1{ 
    background: rgba($primary-color, 0.25) none repeat scroll 0% 0%;
    //transition: transform 0.3s cubic-bezier(0.455, 0.03, 1, 0.545) 0s;
    transition: all .3s ease-in-out;
  }
  &.bg-2{ 
    background: rgba($primary-color, 0.5) none repeat scroll 0% 0%;
    //transition: transform 0.5s cubic-bezier(0.455, 0.03, 1, 0.545) 0s;
    transition: all .5s ease-in-out;
  }
  &.opened{
    //opacity: 1;
    transform: translateY(0px);
  }
} 